import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import IconButton from '@material-ui/core/IconButton';
import ReorderIcon from '@material-ui/icons/Reorder';

import './SideMenu.scss';
import { AccountBalance, LocalShipping, DateRangeSharp } from '@material-ui/icons';
import { WavingHandIcon } from '../AtomComponents';

function SideMenu(props: any) {
  const [sideMenuState, setSideMenuState] = useState(false);
  const [sideMenuSmallDeviceState, setSideMenuSmallDeviceState] = useState(false);
  // const [isHyla] = useState(window.location.href.indexOf('hyla') > -1);

  const sassMenu = (
    <>
      <ul className="px-menu-mobile show-for-sm-only border-top">
        <li>
          <span className="link" data-id="toggleMobileMenu">
            <ReorderIcon
              onClick={() => {
                setSideMenuSmallDeviceState(!sideMenuSmallDeviceState);
              }}
            />
          </span>
        </li>
        {/* <li>
          <NavLink to="/stock-alerts" activeClassName="px-active-navlink">
            <NotificationsIcon />
            <small className="block">Alert</small>
          </NavLink>
        </li>
        <li>
          <NavLink to="/account" activeClassName="px-active-navlink">
            <PersonIcon />
            <small className="block">Account</small>
          </NavLink>
        </li>
        <li>
          <NavLink to="/help" activeClassName="px-active-navlink">
            <HelpIcon />
            <small className="block">Help</small>
          </NavLink>
        </li> */}
      </ul>
      <div
        className={`px-sideMenu-sticky-container  ${
          sideMenuState ? 'px-sideMenu-sticky-container-show-menu' : ''
        }`}>
        <nav
          className={`px-c-SideMenu
          ${props.autoCloseState > 0 ? 'px-menu-autoclose' : 'px-menu-autoclose-off'}
          ${sideMenuState ? 'show-menu' : ''}
          ${sideMenuSmallDeviceState ? 'show-menu-small-device' : ''}
        `}
          onMouseEnter={() => {
            setSideMenuState(true);
          }}
          onMouseLeave={() => {
            setSideMenuState(false);
          }}>
          <MenuList className="padding-0 px-position-sticky-top">
            <MenuItem
              className="px-expand-side-menu padding-0 cell grid-x align-center text-center"
              disableRipple>
              <IconButton
                aria-label="expand/collapse"
                size="small"
                className="p-t-px-8 cell"
                disableRipple>
                <MenuIcon className="px-expand-menu-icon" />
              </IconButton>
            </MenuItem>
            <MenuItem
              className="px-menu-desk padding-0 border-bottom px-sideMenu-heading"
              disableRipple>
              <NavLink
                to="/"
                className="cell grid-x align-left align-middle px-sideMenu-item"
                onClick={(e: any) => {
                  e.preventDefault();
                  props.setAutoCloseState((old: any) => old + 1);
                  setSideMenuState(false);
                  setSideMenuSmallDeviceState(false);
                }}>
                <MenuOpenIcon className="px-sideMenu-item-icon" />
                <h5 className="margin-bottom-0">PO Menu</h5>
              </NavLink>
            </MenuItem>
          </MenuList>
          <div className="px-menu-desk px-position-sticky-top">
            <MenuList className="padding-0">
              <MenuItem className={`${props.menuItemDisplay.Fulfillment} padding-0 border-bottom`}>
                <NavLink
                  exact
                  to="/fulfillment"
                  data-id="home"
                  activeClassName="px-active-navlink"
                  className="cell grid-x align-left align-middle px-sideMenu-item"
                  onClick={() => {
                    props.setAutoCloseState((old: any) => old + 1);
                    setSideMenuSmallDeviceState(false);
                  }}>
                  <LocalShipping className="px-sideMenu-item-icon" />
                  <span>Fulfillment</span>
                </NavLink>
              </MenuItem>
              {props.userType === 'ADMIN' && (
                <MenuItem className={`${props.menuItemDisplay.Funding} padding-0 border-bottom`}>
                  <NavLink
                    to="/funding"
                    data-id="home"
                    activeClassName="px-active-navlink"
                    className="cell grid-x align-left align-middle px-sideMenu-item"
                    onClick={() => {
                      props.setAutoCloseState((old: any) => old + 1);
                      setSideMenuSmallDeviceState(false);
                    }}>
                    <AccountBalance className="px-sideMenu-item-icon" />
                    <span>Funding</span>
                  </NavLink>
                </MenuItem>
              )}
              {props.userType === 'ADMIN' && props.showItemScheduling && (
                <MenuItem
                  className={`${props.menuItemDisplay.ItemScheduling} padding-0 border-bottom`}>
                  <NavLink
                    to="/item-scheduling"
                    data-id="itemScheduling"
                    activeClassName="px-active-navlink"
                    className="cell grid-x align-left align-middle px-sideMenu-item"
                    onClick={() => {
                      props.setAutoCloseState((old: any) => old + 1);
                      setSideMenuSmallDeviceState(false);
                    }}>
                    <WavingHandIcon className="px-sideMenu-item-icon" />
                    <span>Item Scheduling</span>
                  </NavLink>
                </MenuItem>
              )}
              {props.userType === 'ADMIN' && props.showItemScheduling && (
                <MenuItem
                  className={`${props.menuItemDisplay.OfferClearing} padding-0 border-bottom`}>
                  <NavLink
                    to="/po-offer-clearing"
                    data-id="poOfferClearing"
                    activeClassName="px-active-navlink"
                    className="cell grid-x align-left align-middle px-sideMenu-item"
                    onClick={() => {
                      props.setAutoCloseState((old: any) => old + 1);
                      setSideMenuSmallDeviceState(false);
                    }}>
                    <DateRangeSharp className="px-sideMenu-item-icon" />
                    <span>PO Offer Clearing</span>
                  </NavLink>
                </MenuItem>
              )}
            </MenuList>
          </div>
        </nav>
      </div>
    </>
  );

  return <>{sassMenu}</>;
}

function mapStateToProps(state: any) {
  return {
    menuItemDisplay: state.sideMenuState,
  };
}

export default connect(mapStateToProps)(SideMenu);
