import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

// Custom Components
import GridInput from '../../GridInput/GridInput';

// Helper Functions and Components
import { dateFormats, getAggregatedSumOfFields, getLatestDate, manipulateDates } from '../Utils';

// Types
import { awards } from '../../../types/IPreOrders';
import { customSorting } from '../TableCellComponents';
import { dataFormatting } from '../../../_lib/lib';

export const getClosedColumnDefinitions = () => {
  const buyerClosedColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Buyers',
      field: 'buyerName',
      cellRenderer: 'agGroupCellRenderer',
      minWidth: 250,
    },
    {
      headerName: '',
      field: 'isFundingRequired',
      cellRendererFramework: GridInput,
      cellRendererParams: {
        isCheckBox: true,
        disabled: true,
        checkBoxLabel: 'Funding Required',
      },
      minWidth: 100,
    },
    {
      headerName: 'Items',
      field: 'items',
      valueGetter: (params: ValueGetterParams) => params.data.subGrid.length,
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        }),
      minWidth: 100,
    },
    {
      headerName: '% Ending Fulfilled',
      field: 'percentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        const aggregatedEndingFulfilled = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        return ((aggregatedEndingFulfilled / aggregatedAwarded) * 100).toFixed(2);
      },
      comparator: customSorting,
      minWidth: 100,
    },
    {
      headerName: 'Last Shipped Date',
      field: 'shippingEndDate',
      valueFormatter: (params: ValueFormatterParams) =>
        getLatestDate({
          dataSet: params.data.subGrid,
          formatTo: dateFormats.monthIn3Letters,
        }),
      comparator: customSorting,
      minWidth: 150,
    },
  ];

  const buyerClosedDetailsColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Item',
      field: 'phonexItemDescription',
      minWidth: 330,
      pinned: 'left',
    },
    {
      headerName: 'Warehouse',
      field: 'warehouse',
      minWidth: 90,
    },
    {
      headerName: 'SKU',
      field: 'itemNumber',
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      minWidth: 100,
    },
    {
      headerName: 'Price',
      field: 'awardedPrice',
      minWidth: 100,
      cellClass: 'right-align',
      headerClass: 'right-align',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
    },
    {
      headerName: 'Take All?',
      field: 'isTakeAll',
      minWidth: 120,
      valueFormatter: (params: ValueFormatterParams) => (params.value ? 'Y' : 'N'),
    },
    {
      headerName: 'Take % Limit',
      field: 'takeAllLimitPercentage',
      minWidth: 120,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      valueFormatter: (params: ValueFormatterParams) => params.value ?? 0,
      minWidth: 120,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      minWidth: 100,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilledDetail',
      valueGetter: (params: ValueGetterParams) =>
        params.data.quantityReadyToFulfill + params.data.quantityFulfilled,
      minWidth: 120,
    },
    {
      headerName: 'Target % of Ending Fulfilled',
      field: 'targetPercentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        const result = (params.data.awardedQuantity / aggregatedAwarded) * 100;
        return result.toFixed(2);
      },
      comparator: customSorting,
      minWidth: 120,
    },
    {
      headerName: 'Actual % of Ending Fulfilled',
      field: 'actual_per_ending_fulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedEndingFulfilled: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        if (aggregatedEndingFulfilled === 0) return (0).toFixed(2);
        const endingFulfilled = params.data.quantityReadyToFulfill + params.data.quantityFulfilled;
        return ((endingFulfilled / aggregatedEndingFulfilled) * 100).toFixed(2);
      },
      comparator: customSorting,
      minWidth: 120,
    },
    {
      headerName: 'Shipping Start Date',
      field: 'shippingStartDate',
      minWidth: 140,
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      comparator: customSorting,
    },
    {
      headerName: 'Shipping End Date',
      field: 'shippingEndDate',
      minWidth: 160,
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      comparator: customSorting,
    },
  ];

  const itemClosedColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Item',
      field: 'phonexItemDescription',
      cellRenderer: 'agGroupCellRenderer',
      minWidth: 350,
    },
    {
      headerName: 'Warehouse',
      field: 'warehouse',
      minWidth: 120,
    },
    {
      headerName: 'SKU',
      field: 'itemNumber',
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        }),
      minWidth: 100,
    },
    {
      headerName: '% Ending Fulfilled',
      field: 'percentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        const aggregatedEndingFulfilled = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        return ((aggregatedEndingFulfilled / aggregatedAwarded) * 100).toFixed(2);
      },
      comparator: customSorting,
      minWidth: 100,
    },
    {
      headerName: 'Shipping Start Date',
      field: 'shippingStartDate',
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      comparator: customSorting,
      minWidth: 150,
    },
    {
      headerName: 'Shipping End Date',
      field: 'shippingEndDate',
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      minWidth: 150,
      comparator: customSorting,
    },
  ];

  const itemClosedDetailsColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Customer',
      field: 'buyerName',
      cellClass: 'px-po-break-word',
      minWidth: 100,
      pinned: 'left',
    },
    {
      headerName: 'Funding Required',
      field: 'isFundingRequired',
      cellRendererFramework: GridInput,
      cellRendererParams: {
        isCheckBox: true,
        disabled: true,
      },
      minWidth: 100,
    },
    {
      headerName: 'Fully Awarded',
      field: 'fullyAwarded',
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      minWidth: 100,
    },
    {
      headerName: 'Price',
      field: 'awardedPrice',
      minWidth: 100,
      cellClass: 'right-align',
      headerClass: 'right-align',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
    },
    {
      headerName: 'Take All?',
      field: 'isTakeAll',
      minWidth: 100,
      valueFormatter: (params: ValueFormatterParams) => (params.value ? 'Y' : 'N'),
    },
    {
      headerName: 'Take % Limit',
      field: 'takeAllLimitPercentage',
      minWidth: 100,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      valueFormatter: (params: ValueFormatterParams) => params.value ?? 0,
      minWidth: 100,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      minWidth: 100,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilledDetail',
      valueGetter: (params: ValueGetterParams) =>
        params.data.quantityReadyToFulfill + params.data.quantityFulfilled ?? 0,
      minWidth: 100,
    },
    {
      headerName: 'Target % of Ending Fulfilled',
      field: 'targetPercentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        return ((params.data.awardedQuantity / aggregatedAwarded) * 100).toFixed(2);
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.value}`;
      },
      comparator: customSorting,
      minWidth: 130,
    },
    {
      headerName: 'Actual % of Ending Fulfilled',
      field: 'actual_per_ending_fulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedEndingFulfilled: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        if (aggregatedEndingFulfilled === 0) return (0).toFixed(2);
        const endingFulfilled = params.data.quantityReadyToFulfill + params.data.quantityFulfilled;
        return ((endingFulfilled / aggregatedEndingFulfilled) * 100).toFixed(2);
      },
      minWidth: 130,
      comparator: customSorting,
    },
  ];

  return {
    buyerClosedColsDef,
    buyerClosedDetailsColsDef,
    itemClosedColsDef,
    itemClosedDetailsColsDef,
  };
};
